import React from 'react'

const Footer = () => {
    return (
        <div>
            {/* <div class="container box_shadow"> */}
            	<div id="footer">

  				<div class="footer_bottom">
  					<div class="wrap">
  						<div class="container">
                                <div class="fleft copyright">Doan's Landscaping &copy; 2015 </div>
                                  <div class="clear"></div>
  						</div>
  					</div>
  				</div>
                </div>
               
            </div>
            
      
       
    )
}

export default Footer
