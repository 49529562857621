import React from "react";
import "../App.css";

const Home = () => {
  return (
    <div
      style={{
        textAlign: "",
        minHeight: "",
      }}
    >
      {" "}
      <div class="wrap recent_gal_block">
        <div class="container">
          <h2 class="title">Featured content</h2>{" "}
          <ul class="row">
            <li class="span4">
              <div class="proj_block">
                <img src="images/garden-maintenance.jpg" alt="" />
                <a
                  href="images/featured_works/garden-maintenance.jpg"
                  rel="prettyPhoto[portfolio1]"
                >
                  <span class="portfolio_zoom1"></span>
                </a>
                <div class="proj_descr">
                  <p>Gardening maintenance.</p>
                  Gardening maintenance.
                </div>
              </div>
            </li>
            <li class="span4">
              <div class="proj_block">
                <img src="images/landscape-design.jpg" alt="" />
                <a
                  href="images/featured_works/landscape-design.jpg"
                  rel="prettyPhoto[portfolio1]"
                >
                  <span class="portfolio_zoom1"></span>
                </a>
                <div class="proj_descr">
                  <p>Landscaping Design Work.</p>
                  Landscaping Design Work.
                </div>
              </div>
            </li>
            <li class="span4">
              <div class="proj_block">
                <img src="images/landscaping2.png" alt="" />
                <a
                  href="images/featured_works/landscaping2.png"
                  rel="prettyPhoto[portfolio1]"
                >
                  <span class="portfolio_zoom1"></span>
                </a>
                <div class="proj_descr">
                  <p>Retaining Wall.</p>
                  Retaining Wall.
                </div>
              </div>
            </li>
            <div class="clear"></div>
          </ul>
        </div>
      </div>
      <div class="wrap welcome_bg">
        <div class="container">
          <div class="welcome_block">
            <p>
              <span>Creative ideas</span>
              for your garden
            </p>
            Provide best quality work with a very competitive price. All work
            guaranteed
          </div>
        </div>
      </div>
      <div>
        <div class="wrap planning">
          <div class="container">
            <div class="row">
              <a class="span3" href="javascript:void(0);">
                <span class="service_block">
                  <span class="icon_block">
                    <img class="icon1" src="images/icon1.png" alt="" />
                    <img class="icon1_h" src="images/icon1_h.png" alt="" />
                  </span>
                  <span class="link_title">Consultation</span>
                  <span class="service_txt">
                    No work is small for us. We will be on-site and provide a
                    free estimate for all the work.
                  </span>
                </span>
              </a>
              <a class="span3" href="javascript:void(0);">
                <span class="service_block">
                  <span class="icon_block">
                    <img
                      className="icon1"
                      src="images/icon2.png"
                      style={{ width: "120px" }}
                    />
                    <img class="icon1_h" src="images/icon2_h.png" alt="" />
                  </span>
                  <span class="link_title">Landscape Design</span>
                  <span class="service_txt">
                    We design landscape architecture that is customized based on
                    your garden landscape
                  </span>
                </span>
              </a>
              <a class="span3" href="javascript:void(0);">
                <span class="service_block">
                  <span class="icon_block">
                    <img className="icon1" src="images/icon3.png" alt="" />
                    <img className="icon1_h" src="images/icon3_h.png" alt="" />
                  </span>
                  <span class="link_title">Garden Maintenance</span>
                  <span class="service_txt">
                    Our team provide year round garden maintenance. Also provide
                    full maintenance services for commercial companies and
                    builders{" "}
                  </span>
                </span>
              </a>
              <a class="span3" href="javascript:void(0);">
                <span class="service_block">
                  <span class="icon_block">
                    <img className="icon1" src="images/icon4.png" alt="" />
                    <img className="icon1_h" src="images/icon4_h.png" alt="" />
                  </span>
                  <span class="link_title">Installation</span>
                  <span class="service_txt">
                    Our team provide full service for outside system
                    installation including but not limited to sprinkler system,
                    retaining wall, patio
                  </span>
                </span>
              </a>
              <div class="clear"></div>
            </div>
          </div>
        </div>
        <div class="wrap">
          <div class="container">
            <h2 class="title">testimonials</h2>
            <div id="testim_carousel" class="carousel slide">
              <div class="row carousel-inner">
                <div class="item active">
                  <div class="span6 testim_block">
                    <div class="testim_block_txt">
                      We hired Doan Landscaping company to make a sprinkler
                      system for our home and they did an excellent job. When I
                      broke one of the water sprayer the owner came out and help
                      me to reinstall with no charge. Whenever we need
                      repairment for our backyard we always call Doan's
                      landscaping
                    </div>
                    <div class="testim_author">Paul Mai</div>
                  </div>
                  <div class="span6 testim_block">
                    <div class="testim_block_txt">
                      We, North Ridge HOA community in Lynnwood, hired Doan's
                      Landscaping company to maintain our community common area
                      and they did an excellent work in the past 2 years. They
                      also provide other services with very competitive prices
                    </div>
                    <div class="testim_author">
                      North Ridge HOA in Lynnwood, WA
                    </div>
                  </div>
                  <div class="clear"></div>
                </div>
                <div class="item">
                  <div class="span6 testim_block">
                    <div class="testim_block_txt">
                      We hired Doan Landscaping company to make a sprinkler
                      system for our home and they did an excellent job. When I
                      broke one of the water sprayer the owner came out and help
                      me to reinstall with no charge. Whenever we need
                      repairment for our backyard we always call Doan's
                      landscaping
                    </div>
                    <div class="testim_author">Paul Mai</div>
                  </div>
                  <div class="span6 testim_block">
                    <div class="testim_block_txt">
                      We, North Ridge HOA community in Lynnwood, hired Doan's
                      Landscaping company to maintain our community common area
                      and they did an excellent work in the past 2 years. They
                      also provide other services with very competitive prices
                    </div>
                    <div class="testim_author">
                      North Ridge HOA in Lynnwood, WA
                    </div>
                  </div>
                  <div class="clear"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
