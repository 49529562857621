import logo from "./logo.svg";
import "./App.css";
import Home from "./pages/Home";
import About from "./pages/About";
import Services from "./pages/Services";
import Portfolio from "./pages/Portfolio";
import Contact from "./pages/Contact";
//import Slider from "./pages/Slider";
import Header from "./pages/Header";
import Footer from "./pages/Footer";
import React from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";

function App() {
  return (
    <>
    <div class="container box_shadow">
      <Router>
          <Header />
          {" "}
        <Switch>
          <Route path="/" exact component={Home} />
         <Route path="/Services" component={Services} />
          <Route path="/Portfolio" component={Portfolio} />
          <Route path="/Contact" component={Contact} />
            <Route path="/About" component={About} />
             
        </Switch>
      </Router>
        {/* <Slider/> */}
      <Footer />
      </div>
    </>
  );
}

export default App;
