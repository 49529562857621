import React from "react";
import { Link } from "react-router-dom";

const Header = () => {
  return (
    <div>
      {/* <div class="container box_shadow"> */}
      <div class="top_line">
        <p class="fleft">Licensed, Bonded & Insured. #DoanLLC920LT.</p>
        <div class="fright follow_us">
          
        </div>
        <div class="clear"></div>
      </div>
      <div class="header">
        <div class="wrap">
          <div class="container">
            <div class="fleft logo">
              <a href="index.html">
                <img src="images/doan-logo.png" alt="" />
              </a>
            </div>
            <div class="fright top_info">
              <div class="phone_block">
                <div class="phone_in1">
                  <span>Phone:</span> +1 425 772 9499 / 425 770 7021{" "}
                </div>
                <div class="phone_in2">
                  <span> Email:</span>{" "}
                  <a href="mailto:toidoan@frontier.com">
                    {" "}
                    toidoan@frontier.com
                  </a>
                </div>
              </div>
              <div class="table_block">
                <span>Monday - Friday:</span> 10:00AM - 05:00PM
              </div>
            </div>
            <div class="clear"></div>
          </div>

          <div class="navbar navbar_ clearfix">
            <div class="container menu_bg">
              <nav id="main_menu">
                <div class="menu_wrap">
                  <ul class="nav sf-menu">
                    <li>
                      <Link to="/">Home</Link>
                    </li>
                    <li>
                      <Link to="/Services">Services</Link>
                    </li>
                    <li>
                      <Link to="/Portfolio">Portfolio</Link>
                    </li>
                    <li>
                      <Link to="/Contact">Contact</Link>
                    </li>
                    <li>
                      <Link to="/About">About</Link>
                    </li>
                  </ul>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </div>
      <div class="clear"></div>
      <br />
      <br />
    </div>
  );
};

export default Header;
