import React from "react";

const Services = () => {
  return (
    <>
      <div class="page_container">
        <div class="wrap">
          <div class="breadcrumb">
            <div>
              <a href="index.html">Home</a>
              <span>/</span>Services
            </div>
          </div>
          <div class="container">
            <section>
              <div class="row">
                <div class="span4">
                  <h2 class="title">
                    <span>Garden &amp; Lawn Maintenance</span>
                  </h2>
                  <ul class="list-unstyled">
                    <li>Mowing - Edging- Weeding</li>
                    <li>Trimming &amp; Prunning</li>
                    <li>Tree Service Topping</li>
                    <li>Firewood cutting</li>
                    <li>Yard Cleaning</li>
                    <li>Trash Removal</li>
                    <li>Yard Fertilization</li>
                    <li>Pressure Washing</li>
                    <li>Gutter Cleaning</li>
                    <li>Aerating</li>
                    <li>Thatching</li>
                  </ul>
                </div>
                <div class="span4">
                  <h2 class="title">
                    <span>Landscape Design</span>
                  </h2>
                  <ul class="list-unstyled">
                    <li>New Lawn-Sod &amp; Seed</li>
                    <li>Trees &amp; Shrubs Planting</li>
                    <li>Rockery, Timber &amp; R.R. Ties</li>
                    <li>Flower Beds</li>
                    <li>Sidewalks &amp; Patios</li>
                    <li>Water Walls</li>
                  </ul>
                </div>
                <div class="span4">
                  <h2 class="title">
                    <span>Installation</span>
                  </h2>
                  <ul class="list-unstyled">
                    <li>Sprinkler Systems &amp; Repair</li>
                    <li>Rockery, Timber &amp; R.R. Ties</li>
                    <li>Drainage Specialists</li>
                    <li>Gravel &amp; Crushed Rock</li>
                    <li>Concrete Work</li>
                    <li>Retaining Walls</li>
                  </ul>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </>
  );
};

export default Services;
