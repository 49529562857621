import React from "react";

const Portfolio = () => {
  return (
    <div>
      <div class="page_container">
        <div class="wrap">
          <div class="breadcrumb">
            <div>
              <a href="index.html">Home</a>
              <span>/</span>Portfolio
            </div>
          </div>
          <div class="container">
            <div id="options">
              <ul id="filters" class="option-set" data-option-key="filter">
                <li>
                  <a href="#filter" data-option-value="*" class="selected">
                    All
                  </a>
                </li>
                <li>
                  <a href="#filter" data-option-value=".landscaping">
                    Landscaping
                  </a>
                </li>
                <li>
                  <a href="#filter" data-option-value=".garden-work">
                    Garden-work
                  </a>
                </li>
              </ul>
              <div class="clear"></div>
            </div>

            <div class="row">
              {/* <!-- portfolio_block --> */}
              <div class="projects">
                <div
                  class="span6 element landscaping height_2column"
                  data-category="landscaping"
                >
                  <div class="hover_img">
                    <img
                      src="/images/portfolio/landscaping/DSC00916.JPG"
                    />
                    <span class="portfolio_zoom">
                      <a
                        href="images/portfolio/landscaping/DSC00916.JPG"
                        rel="prettyPhoto[portfolio1]"
                      ></a>
                    </span>
                    <span class="portfolio_link">
                      <a href="single_portfolio.html">View item</a>
                    </span>
                  </div>
  	                                                    
                </div>

                <div
                  class="span6 element landscaping height_2column"
                  data-category="landscaping"
                >
                  <div class="hover_img">
                    <img
                      src="images/portfolio/landscaping/DSC00005.JPG"
                      alt="DSC00005.JPG"
                    />
                    <span class="portfolio_zoom">
                      <a
                        href="images/portfolio/landscaping/DSC00005.JPG"
                        rel="prettyPhoto[portfolio1]"
                      ></a>
                    </span>
                    <span class="portfolio_link">
                      <a href="single_portfolio.html">View item</a>
                    </span>
                  </div>
                  {/* <!--
  	                    <div class="item_description">
  	                    <h6><a href="single_portfolio.html">Lorem ipsum dolor</a></h6>
  	                     <div class="descr">Et dicta essent vis, sed vitae dictas vulputate ea,
                          ex zril quaeque mentitum quo.</div>
  	                     </div>
  	                     --> */}
                </div>

                <div
                  class="span6 element landscaping height_2column"
                  data-category="landscaping"
                >
                  <div class="hover_img">
                    <img
                      src="images/portfolio/landscaping/DSC00954.JPG"
                      alt="DSC00954.JPG"
                    />
                    <span class="portfolio_zoom">
                      <a
                        href="/images/portfolio/landscaping/DSC00954.JPG"
                        rel="prettyPhoto[portfolio1]"
                      ></a>
                    </span>
                    <span class="portfolio_link">
                      <a href="single_portfolio.html">View item</a>
                    </span>
                  </div>
                </div>

                <div
                  class="span6 element landscaping height_2column"
                  data-category="landscaping"
                >
                  <div class="hover_img">
                    <img
                      src="images/portfolio/landscaping/DSC01064.JPG"
                      alt="DSC01064.JPG"
                    />
                    <span class="portfolio_zoom">
                      <a
                        href="images/portfolio/landscaping/DSC01064.JPG"
                        rel="prettyPhoto[portfolio1]"
                      ></a>
                    </span>
                    <span class="portfolio_link">
                      <a href="single_portfolio.html">View item</a>
                    </span>
                  </div>
                </div>

                <div
                  class="span6 element landscaping height_2column"
                  data-category="landscaping"
                >
                  <div class="hover_img">
                    <img
                      src="images/portfolio/landscaping/DSC00913.JPG"
                      alt="DSC00913.JPG"
                    />
                    <span class="portfolio_zoom">
                      <a
                        href="images/portfolio/landscaping/DSC00913.JPG"
                        rel="prettyPhoto[portfolio1]"
                      ></a>
                    </span>
                    <span class="portfolio_link">
                      <a href="single_portfolio.html">View item</a>
                    </span>
                  </div>
                  {/* <!--
  	              <div class="item_description">
  	               <h6><a href="single_portfolio.html">Lorem ipsum dolor</a></h6>
  	               <div class="descr">Et dicta essent vis, sed vitae dictas vulputate ea, 
                   ex zril quaeque mentitum quo.</div>
  	                </div>
  	                -->  */}
                </div>

                <div
                  class="span6 element landscaping height_2column"
                  data-category="landscaping"
                >
                  <div class="hover_img">
                    <img
                      src="images/portfolio/landscaping/DSC01069.JPG"
                      alt="DSC01069.JPG"
                    />
                    <span class="portfolio_zoom">
                      <a
                        href="images/portfolio/landscaping/DSC01069.JPG"
                        rel="prettyPhoto[portfolio1]"
                      ></a>
                    </span>
                    <span class="portfolio_link">
                      <a href="single_portfolio.html">View item</a>
                    </span>
                  </div>
                  {/* <!--
  	                     <div class="item_description">
  	                       <h6><a href="single_portfolio.html">Lorem ipsum dolor</a></h6>
  	                       <div class="descr">Et dicta essent vis, sed vitae dictas vulputate ea, ex zril quaeque mentitum quo.</div>
  	                      </div>
  	                      --> */}
                </div>

                <div
                  class="span6 element landscaping height_2column"
                  data-category="landscaping"
                >
                  <div class="hover_img">
                    <img
                      src="images/portfolio/landscaping/DSC00968.JPG"
                      alt="DSC00968.JPG"
                    />
                    <span class="portfolio_zoom">
                      <a
                        href="images/portfolio/landscaping/DSC00968.JPG"
                        rel="prettyPhoto[portfolio1]"
                      ></a>
                    </span>
                    <span class="portfolio_link">
                      <a href="single_portfolio.html">View item</a>
                    </span>
                  </div>
                  {/* <!--
  	                    <div class="item_description">
  	                     <h6><a href="single_portfolio.html">Lorem ipsum dolor</a></h6>
  	                    <div class="descr">Et dicta essent vis, sed vitae dictas vulputate ea, 
                        ex zril quaeque mentitum quo.</div>
  	                    </div>
  	                    --> */}
                </div>

                <div
                  class="span6 element landscaping height_2column"
                  data-category="landscaping"
                >
                  <div class="hover_img">
                    <img
                      src="images/portfolio/landscaping/DSC00961.JPG"
                      alt="DSC00961.JPG"
                    />
                    <span class="portfolio_zoom">
                      <a
                        href="images/portfolio/landscaping/DSC00961.JPG"
                        rel="prettyPhoto[portfolio1]"
                      ></a>
                    </span>
                    <span class="portfolio_link">
                      <a href="single_portfolio.html">View item</a>
                    </span>
                  </div>
                  {/* <!--
  	                   <div class="item_description">
  	                    <h6><a href="single_portfolio.html">Lorem ipsum dolor</a></h6>
  	                    <div class="descr">Et dicta essent vis, sed vitae dictas vulputate ea,
                         ex zril quaeque mentitum quo.</div>
  	                     </div>
  	                        --> */}
                </div>

                <div
                  class="span6 element landscaping height_2column"
                  data-category="landscaping"
                >
                  <div class="hover_img">
                    <img
                      src="images/portfolio/landscaping/DSC00002.JPG"
                      alt="DSC00002.JPG"
                    />
                    <span class="portfolio_zoom">
                      <a
                        href="images/portfolio/landscaping/DSC00002.JPG"
                        rel="prettyPhoto[portfolio1]"
                      ></a>
                    </span>
                    <span class="portfolio_link">
                      <a href="single_portfolio.html">View item</a>
                    </span>
                  </div>
                  {/* <!--
  	                    <div class="item_description">
  	                   <h6><a href="single_portfolio.html">Lorem ipsum dolor</a></h6>
  	                    <div class="descr">Et dicta essent vis, sed vitae dictas vulputate ea, 
                        ex zril quaeque mentitum quo.</div>
  	                    </div>
  	                    --> */}
                </div>

                <div
                  class="span6 element landscaping height_2column"
                  data-category="landscaping"
                >
                  <div class="hover_img">
                    <img
                      src="images/portfolio/landscaping/DSC00009.JPG"
                      alt="DSC00009.JPG"
                    />
                    <span class="portfolio_zoom">
                      <a
                        href="images/portfolio/landscaping/DSC00009.JPG"
                        rel="prettyPhoto[portfolio1]"
                      ></a>
                    </span>
                    <span class="portfolio_link">
                      <a href="single_portfolio.html">View item</a>
                    </span>
                  </div>
                 
                </div>

                <div
                  class="span6 element landscaping height_2column"
                  data-category="landscaping"
                >
                  <div class="hover_img">
                    <img
                      src="images/portfolio/landscaping/DSC00923.JPG"
                      alt="DSC00923.JPG"
                    />
                    <span class="portfolio_zoom">
                      <a
                        href="images/portfolio/landscaping/DSC00923.JPG"
                        rel="prettyPhoto[portfolio1]"
                      ></a>
                    </span>
                    <span class="portfolio_link">
                      <a href="single_portfolio.html">View item</a>
                    </span>
                  </div>
                  {/* <!--
  	                    <div class="item_description">
  	                    <h6><a href="single_portfolio.html">Lorem ipsum dolor</a></h6>
  	                    <div class="descr">Et dicta essent vis, sed vitae dictas vulputate ea,
                         ex zril quaeque mentitum quo.</div>
  	                    </div>
  	                    --> */}
                </div>

                <div
                  class="span6 element landscaping height_2column"
                  data-category="landscaping"
                >
                  <div class="hover_img">
                    <img
                      src="images/portfolio/landscaping/DSC00969.JPG"
                      alt="DSC00969.JPG"
                    />
                    <span class="portfolio_zoom">
                      <a
                        href="images/portfolio/landscaping/DSC00969.JPG"
                        rel="prettyPhoto[portfolio1]"
                      ></a>
                    </span>
                    <span class="portfolio_link">
                      <a href="single_portfolio.html">View item</a>
                    </span>
                  </div>
                  {/* <!--
  	              <div class="item_description">
  	                <h6><a href="single_portfolio.html">Lorem ipsum dolor</a></h6>
  	                <div class="descr">Et dicta essent vis, sed vitae dictas vulputate ea,
                     ex zril quaeque mentitum quo.</div>
  	                 </div>
  	                -->                                     
                    */}
                </div>

                <div
                  class="span6 element landscaping height_2column"
                  data-category="landscaping"
                >
                  <div class="hover_img">
                    <img
                      src="images/portfolio/landscaping/DSC00004.JPG"
                      alt="DSC00004.JPG"
                    />
                    <span class="portfolio_zoom">
                      <a
                        href="images/portfolio/landscaping/DSC00004.JPG"
                        rel="prettyPhoto[portfolio1]"
                      ></a>
                    </span>
                    <span class="portfolio_link">
                      <a href="single_portfolio.html">View item</a>
                    </span>
                  </div>
                  {/* <!--
  	                <div class="item_description">
  	                  <h6><a href="single_portfolio.html">Lorem ipsum dolor</a></h6>
  	                   <div class="descr">Et dicta essent vis, sed vitae dictas vulputate ea,
                        ex zril quaeque mentitum quo.</div>
  	                    </div>
  	                    -->                                    
                         */}
                </div>

                <div
                  class="span6 element landscaping height_2column"
                  data-category="landscaping"
                >
                  <div class="hover_img">
                    <img
                      src="images/portfolio/landscaping/DSC00917.JPG"
                      alt="DSC00917.JPG"
                    />
                    <span class="portfolio_zoom">
                      <a
                        href="images/portfolio/landscaping/DSC00917.JPG"
                        rel="prettyPhoto[portfolio1]"
                      ></a>
                    </span>
                    <span class="portfolio_link">
                      <a href="single_portfolio.html">View item</a>
                    </span>
                  </div>
                  {/* <!--
  	                    <div class="item_description">
  	                      <h6><a href="single_portfolio.html">Lorem ipsum dolor</a></h6>
  	                      <div class="descr">Et dicta essent vis, sed vitae dictas vulputate ea, 
                          ex zril quaeque mentitum quo.</div>
  	                      </div>
  	                    -->                                    
                         */}
                </div>

                <div
                  class="span6 element landscaping height_2column"
                  data-category="landscaping"
                >
                  <div class="hover_img">
                    <img
                      src="images/portfolio/landscaping/DSC00967.JPG"
                      alt="DSC00967.JPG"
                    />
                    <span class="portfolio_zoom">
                      <a
                        href="images/portfolio/landscaping/DSC00967.JPG"
                        rel="prettyPhoto[portfolio1]"
                      ></a>
                    </span>
                    <span class="portfolio_link">
                      <a href="single_portfolio.html">View item</a>
                    </span>
                  </div>
                  {/* <!--
  	                  <div class="item_description">
  	                    <h6><a href="single_portfolio.html">Lorem ipsum dolor</a></h6>
  	                    <div class="descr">Et dicta essent vis, sed vitae dictas vulputate ea, 
                        ex zril quaeque mentitum quo.</div>
  	                  </div>
  	                                                                        
                        -->                                   
                          */}
                </div>

                <div
                  class="span6 element landscaping height_2column"
                  data-category="landscaping"
                >
                  <div class="hover_img">
                    <img
                      src="images/portfolio/landscaping/DSC00915.JPG"
                      alt="DSC00915.JPG"
                    />
                    <span class="portfolio_zoom">
                      <a
                        href="images/portfolio/landscaping/DSC00915.JPG"
                        rel="prettyPhoto[portfolio1]"
                      ></a>
                    </span>
                    <span class="portfolio_link">
                      <a href="single_portfolio.html">View item</a>
                    </span>
                  </div>
                  {/* <!--
  	                     <div class="item_description">
  	                      <h6><a href="single_portfolio.html">Lorem ipsum dolor</a></h6>
  	                      <div class="descr">Et dicta essent vis, sed vitae dictas vulputate ea, 
                          ex zril quaeque mentitum quo.</div>
  	                     </div>
  	                  -->                                    
                       */}
                </div>

                <div
                  class="span6 element landscaping height_2column"
                  data-category="landscaping"
                >
                  <div class="hover_img">
                    <img
                      src="images/portfolio/landscaping/DSC00925.JPG"
                      alt="DSC00925.JPG"
                    />
                    <span class="portfolio_zoom">
                      <a
                        href="images/portfolio/landscaping/DSC00925.JPG"
                        rel="prettyPhoto[portfolio1]"
                      ></a>
                    </span>
                    <span class="portfolio_link">
                      <a href="single_portfolio.html">View item</a>
                    </span>
                  </div>
                  {/* <!--
  	                   <div class="item_description">
  	                    <h6><a href="single_portfolio.html">Lorem ipsum dolor</a></h6>
  	                    <div class="descr">Et dicta essent vis, sed vitae dictas vulputate ea, ex zril quaeque mentitum quo.</div>
  	                   </div>
  	                   -->                                   
                         */}
                </div>

                <div
                  class="span6 element landscaping height_2column"
                  data-category="landscaping"
                >
                  <div class="hover_img">
                    <img
                      src="images/portfolio/landscaping/DSC00922.JPG"
                      alt="DSC00922.JPG"
                    />
                    <span class="portfolio_zoom">
                      <a
                        href="images/portfolio/landscaping/DSC00922.JPG"
                        rel="prettyPhoto[portfolio1]"
                      ></a>
                    </span>
                    <span class="portfolio_link">
                      <a href="single_portfolio.html">View item</a>
                    </span>
                  </div>
                  {/* <!--
  	                    <div class="item_description">
  	                      <h6><a href="single_portfolio.html">Lorem ipsum dolor</a></h6>
  	                      <div class="descr">Et dicta essent vis, sed vitae dictas vulputate ea,
                           ex zril quaeque mentitum quo.</div>
  	                      </div>
  	                   -->                                     
                       */}
                </div>

                <div
                  class="span6 element landscaping height_2column"
                  data-category="landscaping"
                >
                  <div class="hover_img">
                    <img
                      src="images/portfolio/landscaping/DSC00003.JPG"
                      alt="DSC00003.JPG"
                    />
                    <span class="portfolio_zoom">
                      <a
                        href="images/portfolio/landscaping/DSC00003.JPG"
                        rel="prettyPhoto[portfolio1]"
                      ></a>
                    </span>
                    <span class="portfolio_link">
                      <a href="single_portfolio.html">View item</a>
                    </span>
                  </div>
                  {/* <!--
  	                 <div class="item_description">
  	                  <h6><a href="single_portfolio.html">Lorem ipsum dolor</a></h6>
  	                  <div class="descr">Et dicta essent vis, sed vitae dictas vulputate ea, ex zril quaeque mentitum quo.</div>
  	                  </div>
  	                                                                        
                      -->                                    
                       */}
                </div>

                <div
                  class="span6 element landscaping height_2column"
                  data-category="landscaping"
                >
                  <div class="hover_img">
                    <img
                      src="images/portfolio/landscaping/DSC00924.JPG"
                      alt="DSC00924.JPG"
                    />
                    <span class="portfolio_zoom">
                      <a
                        href="images/portfolio/landscaping/DSC00924.JPG"
                        rel="prettyPhoto[portfolio1]"
                      ></a>
                    </span>
                    <span class="portfolio_link">
                      <a href="single_portfolio.html">View item</a>
                    </span>
                  </div>
                  {/* <!--
  	                     <div class="item_description">
  	                      <h6><a href="single_portfolio.html">Lorem ipsum dolor</a></h6>
  	                        <div class="descr">Et dicta essent vis, sed vitae dictas vulputate ea,
                             ex zril quaeque mentitum quo.</div>
  	                     </div>
  	                       -->                                  
                              */}
                </div>

                <div
                  class="span6 element landscaping height_2column"
                  data-category="landscaping"
                >
                  <div class="hover_img">
                    <img
                      src="images/portfolio/landscaping/DSC00914.JPG"
                      alt="DSC00914.JPG"
                    />
                    <span class="portfolio_zoom">
                      <a
                        href="images/portfolio/landscaping/DSC00914.JPG"
                        rel="prettyPhoto[portfolio1]"
                      ></a>
                    </span>
                    <span class="portfolio_link">
                      <a href="single_portfolio.html">View item</a>
                    </span>
                  </div>
                  {/* <!--
  	                   <div class="item_description">
  	                      <h6><a href="single_portfolio.html">Lorem ipsum dolor</a></h6>
  	                      <div class="descr">Et dicta essent vis, sed vitae dictas vulputate ea,
                           ex zril quaeque mentitum quo.</div>
  	                      </div>
  	                    -->                                    
                         */}
                </div>

                <div
                  class="span6 element landscaping height_2column"
                  data-category="landscaping"
                >
                  <div class="hover_img">
                    <img
                      src="images/portfolio/landscaping/DSC00949.JPG"
                      alt="DSC00949.JPG"
                    />
                    <span class="portfolio_zoom">
                      <a
                        href="images/portfolio/landscaping/DSC00949.JPG"
                        rel="prettyPhoto[portfolio1]"
                      ></a>
                    </span>
                    <span class="portfolio_link">
                      <a href="single_portfolio.html">View item</a>
                    </span>
                  </div>
                  {/* <!--
  	                      <div class="item_description">
  	                         <h6><a href="single_portfolio.html">Lorem ipsum dolor</a></h6>
  	                         <div class="descr">Et dicta essent vis, sed vitae dictas vulputate ea,
                              ex zril quaeque mentitum quo.</div>
  	                        </div>
  	                    -->                                    
                         */}
                </div>

                <div
                  class="span6 element landscaping height_2column"
                  data-category="landscaping"
                >
                  <div class="hover_img">
                    <img
                      src="images/portfolio/landscaping/DSC00918.JPG"
                      alt="DSC00918.JPG"
                    />
                    <span class="portfolio_zoom">
                      <a
                        href="images/portfolio/landscaping/DSC00918.JPG"
                        rel="prettyPhoto[portfolio1]"
                      ></a>
                    </span>
                    <span class="portfolio_link">
                      <a href="single_portfolio.html">View item</a>
                    </span>
                  </div>
                  {/* <!--
  	               <div class="item_description">
  	                 <h6><a href="single_portfolio.html">Lorem ipsum dolor</a></h6>
  	                  <div class="descr">Et dicta essent vis, sed vitae dictas vulputate ea, 
                      ex zril quaeque mentitum quo.</div>
  	                </div>
  	                 --> */}
                </div>

                <div
                  class="span6 element landscaping height_2column"
                  data-category="landscaping"
                >
                  <div class="hover_img">
                    <img
                      src="images/portfolio/landscaping/DSC00971.JPG"
                      alt="DSC00971.JPG"
                    />
                    <span class="portfolio_zoom">
                      <a
                        href="images/portfolio/landscaping/DSC00971.JPG"
                        rel="prettyPhoto[portfolio1]"
                      ></a>
                    </span>
                    <span class="portfolio_link">
                      <a href="single_portfolio.html">View item</a>
                    </span>
                  </div>
                  {/* <!--
  	                   <div class="item_description">
  	                    <h6><a href="single_portfolio.html">Lorem ipsum dolor</a></h6>
  	                    <div class="descr">Et dicta essent vis, sed vitae dictas vulputate 
                        ea, ex zril quaeque mentitum quo.</div>
  	                    </div>
  	                  -->  */}
                </div>

                <div
                  class="span6 element landscaping height_2column"
                  data-category="landscaping"
                >
                  <div class="hover_img">
                    <img
                      src="images/portfolio/landscaping/DSC00008.JPG"
                      alt="DSC00008.JPG"
                    />
                    <span class="portfolio_zoom">
                      <a
                        href="images/portfolio/landscaping/DSC00008.JPG"
                        rel="prettyPhoto[portfolio1]"
                      ></a>
                    </span>
                    <span class="portfolio_link">
                      <a href="single_portfolio.html">View item</a>
                    </span>
                  </div>
                  {/* <!--
  	                  <div class="item_description">
  	                   <h6><a href="single_portfolio.html">Lorem ipsum dolor</a></h6>
  	                    <div class="descr">Et dicta essent vis, sed vitae dictas vulputate ea,
                         ex zril quaeque mentitum quo.</div>
  	                     </div>
  	                    --> */}
                </div>

                <div
                  class="span6 element landscaping height_2column"
                  data-category="landscaping"
                >
                  <div class="hover_img">
                    <img
                      src="images/portfolio/landscaping/DSC00970.JPG"
                      alt="DSC00970.JPG"
                    />
                    <span class="portfolio_zoom">
                      <a
                        href="images/portfolio/landscaping/DSC00970.JPG"
                        rel="prettyPhoto[portfolio1]"
                      ></a>
                    </span>
                    <span class="portfolio_link">
                      <a href="single_portfolio.html">View item</a>
                    </span>
                  </div>
                  {/* <!--
  	                  <div class="item_description">
  	                 <h6><a href="single_portfolio.html">Lorem ipsum dolor</a></h6>
  	                 <div class="descr">Et dicta essent vis, sed vitae dictas vulputate ea, 
                     ex zril quaeque mentitum quo.</div>
  	                  </div>
  	                  -->  */}
                </div>

                <div
                  class="span6 element garden-work height_2column"
                  data-category="garden-work"
                >
                  <div class="hover_img">
                    <img
                      src="images/portfolio/garden-work/DSC01035.JPG"
                      alt="DSC01035.JPG"
                    />
                    <span class="portfolio_zoom">
                      <a
                        href="images/portfolio/garden-work/DSC01035.JPG"
                        rel="prettyPhoto[portfolio1]"
                      ></a>
                    </span>
                    <span class="portfolio_link">
                      <a href="single_portfolio.html">View item</a>
                    </span>
                  </div>
                  {/* <!--
  	                      <div class="item_description">
  	                          <h6><a href="single_portfolio.html">Lorem ipsum dolor</a></h6>
  	                          <div class="descr">Et dicta essent vis, sed vitae dictas vulputate ea, ex zril quaeque mentitum quo.</div>
  	                        </div>
  	                     -->                                   
                           */}
                </div>

                <div
                  class="span6 element garden-work height_2column"
                  data-category="garden-work"
                >
                  <div class="hover_img">
                    <img
                      src="images/portfolio/garden-work/DSC01037.JPG"
                      alt="DSC01037.JPG"
                    />
                    <span class="portfolio_zoom">
                      <a
                        href="images/portfolio/garden-work/DSC01037.JPG"
                        rel="prettyPhoto[portfolio1]"
                      ></a>
                    </span>
                    <span class="portfolio_link">
                      <a href="single_portfolio.html">View item</a>
                    </span>
                  </div>
                  {/* <!--
  	                 <div class="item_description">
  	                 <h6><a href="single_portfolio.html">Lorem ipsum dolor</a></h6>
  	                  <div class="descr">Et dicta essent vis, sed vitae dictas vulputate ea,
                       ex zril quaeque mentitum quo.</div>
  	                  </div>
  	                --> */}
                </div>

                <div
                  class="span6 element garden-work height_2column"
                  data-category="garden-work"
                >
                  <div class="hover_img">
                    <img
                      src="images/portfolio/garden-work/DSC01055.JPG"
                      alt="DSC01055.JPG"
                    />
                    <span class="portfolio_zoom">
                      <a
                        href="images/portfolio/garden-work/DSC01055.JPG"
                        rel="prettyPhoto[portfolio1]"
                      ></a>
                    </span>
                    <span class="portfolio_link">
                      <a href="single_portfolio.html">View item</a>
                    </span>
                  </div>
                  {/* <!--
  	                  <div class="item_description">
  	                   <h6><a href="single_portfolio.html">Lorem ipsum dolor</a></h6>
  	                    <div class="descr">Et dicta essent vis, sed vitae dictas vulputate ea,
                         ex zril quaeque mentitum quo.</div>
  	                     </div>
  	                     -->  */}
                </div>

                <div
                  class="span6 element garden-work height_2column"
                  data-category="garden-work"
                >
                  <div class="hover_img">
                    <img
                      src="images/portfolio/garden-work/DSC01038.JPG"
                      alt="DSC01038.JPG"
                    />
                    <span class="portfolio_zoom">
                      <a
                        href="images/portfolio/garden-work/DSC01038.JPG"
                        rel="prettyPhoto[portfolio1]"
                      ></a>
                    </span>
                    <span class="portfolio_link">
                      <a href="single_portfolio.html">View item</a>
                    </span>
                  </div>
                  {/* <!--
  	                    <div class="item_description">
  	                    <h6><a href="single_portfolio.html">Lorem ipsum dolor</a></h6>
  	                    <div class="descr">Et dicta essent vis, sed vitae dictas vulputate ea,
                         ex zril quaeque mentitum quo.</div>
  	                      </div>
  	                     -->  */}
                </div>

                <div
                  class="span6 element garden-work height_2column"
                  data-category="garden-work"
                >
                  <div class="hover_img">
                    <img
                      src="images/portfolio/garden-work/DSC00975.JPG"
                      alt="DSC00975.JPG"
                    />
                    <span class="portfolio_zoom">
                      <a
                        href="images/portfolio/garden-work/DSC00975.JPG"
                        rel="prettyPhoto[portfolio1]"
                      ></a>
                    </span>
                    <span class="portfolio_link">
                      <a href="single_portfolio.html">View item</a>
                    </span>
                  </div>
                  {/* <!--
  	                 <div class="item_description">
  	               <h6><a href="single_portfolio.html">Lorem ipsum dolor</a></h6>
  	               <div class="descr">Et dicta essent vis, sed vitae dictas vulputate ea,
                    ex zril quaeque mentitum quo.</div>
  	                 </div>
  	                    -->     */}
                </div>

                <div
                  class="span6 element garden-work height_2column"
                  data-category="garden-work"
                >
                  <div class="hover_img">
                    <img
                      src="images/portfolio/garden-work/DSC01034.JPG"
                      alt="DSC01034.JPG"
                    />
                    <span class="portfolio_zoom">
                      <a
                        href="images/portfolio/garden-work/DSC01034.JPG"
                        rel="prettyPhoto[portfolio1]"
                      ></a>
                    </span>
                    <span class="portfolio_link">
                      <a href="single_portfolio.html">View item</a>
                    </span>
                  </div>
                  {/* <!--
  	                     <div class="item_description">
  	                      <h6><a href="single_portfolio.html">Lorem ipsum dolor</a></h6>
  	                      <div class="descr">Et dicta essent vis, sed vitae dictas vulputate ea,
                           ex zril quaeque mentitum quo.</div>
  	                      </div>
  	                  -->                                   
                        */}
                </div>

                <div
                  class="span6 element garden-work height_2column"
                  data-category="garden-work"
                >
                  <div class="hover_img">
                    <img
                      src="images/portfolio/garden-work/DSC01036.JPG"
                      alt="DSC01036.JPG"
                    />
                    <span class="portfolio_zoom">
                      <a
                        href="images/portfolio/garden-work/DSC01036.JPG"
                        rel="prettyPhoto[portfolio1]"
                      ></a>
                    </span>
                    <span class="portfolio_link">
                      <a href="single_portfolio.html">View item</a>
                    </span>
                  </div>
                  {/* <!--
  	                  <div class="item_description">
  	                    <h6><a href="single_portfolio.html">Lorem ipsum dolor</a></h6>
  	                    <div class="descr">Et dicta essent vis, sed vitae dictas vulputate ea, ex zril quaeque mentitum quo.</div>
  	                  </div>
  	                  -->                                    
                       */}
                </div>

                <div
                  class="span6 element garden-work height_2column"
                  data-category="garden-work"
                >
                  <div class="hover_img">
                    <img
                      src="images/portfolio/garden-work/DSC01056.JPG"
                      alt="DSC01056.JPG"
                    />
                    <span class="portfolio_zoom">
                      <a
                        href="images/portfolio/garden-work/DSC01056.JPG"
                        rel="prettyPhoto[portfolio1]"
                      ></a>
                    </span>
                    <span class="portfolio_link">
                      <a href="single_portfolio.html">View item</a>
                    </span>
                  </div>
                  {/* <!--
  	                    <div class="item_description">
  	                    <h6><a href="single_portfolio.html">Lorem ipsum dolor</a></h6>
  	                    <div class="descr">Et dicta essent vis, sed vitae dictas vulputate ea,
                         ex zril quaeque mentitum quo.</div>
  	                     </div>
  	                    --> */}
                </div>

                <div
                  class="span6 element garden-work height_2column"
                  data-category="garden-work"
                >
                  <div class="hover_img">
                    <img
                      src="images/portfolio/garden-work/DSC01057.JPG"
                      alt="DSC01057.JPG"
                    />
                    <span class="portfolio_zoom">
                      <a
                        href="images/portfolio/garden-work/DSC01057.JPG"
                        rel="prettyPhoto[portfolio1]"
                      ></a>
                    </span>
                    <span class="portfolio_link">
                      <a href="single_portfolio.html">View item</a>
                    </span>
                  </div>
                  {/* <!--
  	                   <div class="item_description">
  	                  <h6><a href="single_portfolio.html">Lorem ipsum dolor</a></h6>
  	                 <div class="descr">Et dicta essent vis, sed vitae dictas vulputate ea,
                      ex zril quaeque mentitum quo.</div>
  	                  </div>
  	                  -->  */}
                </div>

                <div
                  class="span6 element garden-work height_2column"
                  data-category="garden-work"
                >
                  <div class="hover_img">
                    <img
                      src="images/portfolio/garden-work/DSC00974.JPG"
                      alt="DSC00974.JPG"
                    />
                    <span class="portfolio_zoom">
                      <a
                        href="images/portfolio/garden-work/DSC00974.JPG"
                        rel="prettyPhoto[portfolio1]"
                      ></a>
                    </span>
                    <span class="portfolio_link">
                      <a href="single_portfolio.html">View item</a>
                    </span>
                  </div>
                  {/* <!--
  	                  <div class="item_description">
  	                  <h6><a href="single_portfolio.html">Lorem ipsum dolor</a></h6>
  	                 <div class="descr">Et dicta essent vis, sed vitae dictas vulputate ea, 
                     ex zril quaeque mentitum quo.</div>
  	                </div>
  	                --> */}
                </div>

                <div
                  class="span6 element garden-work height_2column"
                  data-category="garden-work"
                >
                  <div class="hover_img">
                    <img
                      src="images/portfolio/garden-work/DSC01060.JPG"
                      alt="DSC01060.JPG"
                    />
                    <span class="portfolio_zoom">
                      <a
                        href="images/portfolio/garden-work/DSC01060.JPG"
                        rel="prettyPhoto[portfolio1]"
                      ></a>
                    </span>
                    <span class="portfolio_link">
                      <a href="single_portfolio.html">View item</a>
                    </span>
                  </div>
                  {/* <!--
  	                  <div class="item_description">
  	                   <h6><a href="single_portfolio.html">Lorem ipsum dolor</a></h6>
  	                    <div class="descr">Et dicta essent vis, sed vitae dictas vulputate ea, 
                        ex zril quaeque mentitum quo.</div>
  	                    </div>
  	                    -->  */}
                </div>

                <div
                  class="span6 element garden-work height_2column"
                  data-category="garden-work"
                >
                  <div class="hover_img">
                    <img
                      src="images/portfolio/garden-work/DSC01059.JPG"
                      alt="DSC01059.JPG"
                    />
                    <span class="portfolio_zoom">
                      <a
                        href="images/portfolio/garden-work/DSC01059.JPG"
                        rel="prettyPhoto[portfolio1]"
                      ></a>
                    </span>
                    <span class="portfolio_link">
                      <a href="single_portfolio.html">View item</a>
                    </span>
                  </div>
                  {/* <!--
  	                 <div class="item_description">
  	                   <h6><a href="single_portfolio.html">Lorem ipsum dolor</a></h6>
  	                  <div class="descr">Et dicta essent vis, sed vitae dictas vulputate ea, 
                      ex zril quaeque mentitum quo.</div>
  	                  </div>
  	                  --> */}
                </div>

                <div
                  class="span6 element garden-work height_2column"
                  data-category="garden-work"
                >
                  <div class="hover_img">
                    <img
                      src="images/portfolio/garden-work/DSC01058.JPG"
                      alt="DSC01058.JPG"
                    />
                    <span class="portfolio_zoom">
                      <a
                        href="images/portfolio/garden-work/DSC01058.JPG"
                        rel="prettyPhoto[portfolio1]"
                      ></a>
                    </span>
                    <span class="portfolio_link">
                      <a href="single_portfolio.html">View item</a>
                    </span>
                  </div>
                  {/* <!--
  	                  <div class="item_description">
  	                    <h6><a href="single_portfolio.html">Lorem ipsum dolor</a></h6>
  	                    <div class="descr">Et dicta essent vis, sed vitae dictas vulputate ea, 
                        ex zril quaeque mentitum quo.</div>
  	                    </div>
  	                    --> */}
                </div>

                <div
                  class="span6 element garden-work height_2column"
                  data-category="garden-work"
                >
                  <div class="hover_img">
                    <img
                      src="images/portfolio/garden-work/DSC00973.JPG"
                      alt="DSC00973.JPG"
                    />
                    <span class="portfolio_zoom">
                      <a
                        href="images/portfolio/garden-work/DSC00973.JPG"
                        rel="prettyPhoto[portfolio1]"
                      ></a>
                    </span>
                    <span class="portfolio_link">
                      <a href="single_portfolio.html">View item</a>
                    </span>
                  </div>
                  {/* <!--
  	                <div class="item_description">
  	                  <h6><a href="single_portfolio.html">Lorem ipsum dolor</a></h6>
  	                  <div class="descr">Et dicta essent vis, sed vitae dictas vulputate ea,
                       ex zril quaeque mentitum quo.</div>
  	                  </div>
  	                  -->                                   
                        */}
                </div>

                <div
                  class="span6 element garden-work height_2column"
                  data-category="garden-work"
                >
                  <div class="hover_img">
                    <img
                      src="images/portfolio/garden-work/DSC00991.JPG"
                      alt="DSC00991.JPG"
                    />
                    <span class="portfolio_zoom">
                      <a
                        href="images/portfolio/garden-work/DSC00991.JPG"
                        rel="prettyPhoto[portfolio1]"
                      ></a>
                    </span>
                    <span class="portfolio_link">
                      <a href="single_portfolio.html">View item</a>
                    </span>
                  </div>
                  {/* <!--
  	                  <div class="item_description">
  	                   <h6><a href="single_portfolio.html">Lorem ipsum dolor</a></h6>
  	                   <div class="descr">Et dicta essent vis, sed vitae dictas vulputate ea, 
                       ex zril quaeque mentitum quo.</div>
  	                  </div>
  	                                                                       
                      -->                                    
                       */}
                </div>

                <div
                  class="span6 element garden-work height_2column"
                  data-category="garden-work"
                >
                  <div class="hover_img">
                    <img
                      src="images/portfolio/garden-work/DSC01061.JPG"
                      alt="DSC01061.JPG"
                    />
                    <span class="portfolio_zoom">
                      <a
                        href="images/portfolio/garden-work/DSC01061.JPG"
                        rel="prettyPhoto[portfolio1]"
                      ></a>
                    </span>
                    <span class="portfolio_link">
                      <a href="single_portfolio.html">View item</a>
                    </span>
                  </div>
                  {/* <!--
  	                    <div class="item_description">
  	                        <h6><a href="single_portfolio.html">Lorem ipsum dolor</a></h6>
  	                        <div class="descr">Et dicta essent vis, sed vitae dictas vulputate ea,
                             ex zril quaeque mentitum quo.</div>
  	                    </div>
  	                    -->                                    
                         */}
                </div>

                <div
                  class="span6 element garden-work height_2column"
                  data-category="garden-work"
                >
                  <div class="hover_img">
                    <img
                      src="images/portfolio/garden-work/DSC00976.JPG"
                      alt="DSC00976.JPG"
                    />
                    <span class="portfolio_zoom">
                      <a
                        href="images/portfolio/garden-work/DSC00976.JPG"
                        rel="prettyPhoto[portfolio1]"
                      ></a>
                    </span>
                    <span class="portfolio_link">
                      <a href="single_portfolio.html">View item</a>
                    </span>
                  </div>
                  {/* <!--
  	                  <div class="item_description">
  	                  <h6><a href="single_portfolio.html">Lorem ipsum dolor</a></h6>
  	                  <div class="descr">Et dicta essent vis, sed vitae dictas vulputate ea, 
                      ex zril quaeque mentitum quo.</div>
  	                  </div>
  	                  -->                                    
                       */}
                </div>

                <div class="clear"></div>
              </div>
              {/* <!-- //portfolio_block -->    */}
            </div>
          </div>
        </div>
        {/* <!--//MAIN CONTENT AREA--> */}
      </div>
    </div>
  );
};

export default Portfolio;
